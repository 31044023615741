<template>
  <v-card class="pa-8">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3>รายละเอียดออเดอร์</h3>
      <v-row>
        <v-col cols="3"
          ><p class="pt-2">หมายเลขออเดอร์*</p>
          <v-text-field
            v-model="orderNo"
            label="หมายเลขออเดอร์"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          ><p class="pt-2">ประเภทการรับออเดอร์*</p>
          <v-text-field
            v-model="orderType"
            label="ประเภทการรับออเดอร์"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          ><p class="pt-2">วันที่สั่งของ*</p>
          <v-text-field
            v-model="orderdate"
            label="วันที่สั่งของ"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3"
          ><p class="pt-2">ราคารวม*</p>
          <v-text-field
            v-model="totalPrice"
            label="ราคารวม"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          ><p class="pt-2">ประเภทการจ่ายเงิน*</p>
          <v-text-field
            v-model="paymentType"
            label="ประเภทการจ่ายเงิน"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3"
          ><p class="pt-2">ชื่อผู้รับ*</p>
          <v-text-field
            v-model="fullname"
            label="ชื่อผู้รับ"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          ><p class="pt-2">หมายเลขโทรศัพท์*</p>
          <v-text-field
            v-model="tel"
            label="หมายเลขโทรศัพท์"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-if="orderType !== 'รับสินค้าเองที่บริษัท'"
          ><p class="pt-2">ที่อยู่จัดส่ง*</p>
          <v-text-field
            v-model="address"
            label="ที่อยู่จัดส่ง"
            outlined
            dense
            :rules="rules.required"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-img
            :src="slipImg"
            v-if="status === 'ชำระเงินแล้วรอจัดเตรียมสินค้า'"
            max-width="400px"
            max-height="400px"
          ></v-img>
        </v-col>
        <v-col cols="12">
          <p class="pt-2">สถานะปัจจุบัน:*</p>
          <v-chip>{{ status }}</v-chip>
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="
              status == 'รอการยืนยัน' && orderType !== 'รับสินค้าเองที่บริษัท'
            "
            @click="step1()"
            >ยืนยันออเดอร์</v-btn
          >
          <v-btn
            v-if="
              status == 'รอการยืนยัน' && orderType == 'รับสินค้าเองที่บริษัท'
            "
            @click="step3()"
            >ยืนยันออเดอร์ให้ลูกค้ามารับของ</v-btn
          >
          <v-btn
            v-if="status == 'ชำระเงินแล้วรอจัดเตรียมสินค้า'"
            color="red"
            dark
            @click="stepback()"
            >สลิปไม่ถูกต้อง ส่งให้ลูกค้าส่งใหม่</v-btn
          >
          <v-btn
            class="ml-4"
            v-if="status == 'ชำระเงินแล้วรอจัดเตรียมสินค้า'"
            color="green"
            dark
            @click="step2()"
            >ยืนยันการรับเงินและส่งของแล้ว</v-btn
          >
          <v-btn
            v-if="status == 'ยืนยันสินค้าให้ลูกค้ามารับของ'"
            color="green"
            dark
            @click="step2()"
            >ลูกค้ามารับของและชำระเงินแล้ว</v-btn
          >
        </v-col>
      </v-row>
      <h3>ออเดอร์ที่สั่ง</h3>
      <v-data-table
        :headers="headers"
        :items="orderProducts"
        :items-per-page="15"
        class="elevation-1"
      >
        <template v-slot:[`item.product.imgUrl`]="{ item }">
          <img :src="item.product.imgUrl" height="80px" />
        </template>
        <!-- <template v-slot:[`item.amount11`]="{ item }">
          <span>{{ item.amount11 }}</span>
        </template> -->
      </v-data-table>
      <!-- <v-row v-for="(i, index) in orderProducts" :key="index">
        <v-col cols="2">{{ i.product.productName }}</v-col>
        <v-col cols="2">{{ i.product.description }}</v-col>
        <v-col cols="2"><img :src="i.product.imgUrl" width="100px" /></v-col>
        <v-col cols="2">{{ i.amountTypePrice }} บาท</v-col>
        <v-col cols="1"> {{ i.amount }} {{ i.amountType }}</v-col>
        <v-col cols="1"> {{ i.note }}</v-col>
      </v-row> -->

      <br />
      <br />
      <v-row>
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      address: "",
      // approveFlag: true
      count: 1,
      id: "",
      orderNo: "",
      orderType: "",
      orderdate: "",
      paymentType: "",
      status: "",
      slipImg: "",
      itemsStatus: [
        { text: "ยืนยันแล้วรอชำระเงิน", value: "ยืนยันแล้วรอชำระเงิน" },
        { text: "ถูกจัดส่งแล้ว", value: "ถูกจัดส่งแล้ว" },
      ],
      tel: "",
      totalPrice: null,
      userId: null,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      fullname: "",
      firstname: "",
      lastname: "",
      orderProducts: [],
      headers: [
        { text: "ชื่อสินค้า", value: "product.productName" },
        { text: "รายละเอียดสินค้า", value: "product.description" },
        { text: "รูปสินค้า", value: "product.imgUrl" },
        { text: "ราคา", value: "amountTypePrice" },
        // { text: "จำนวน", value: "amount" },
        { text: "จำนวน", value: "count" },
        { text: "หมายเหตุ", value: "note" },
      ],
    };
  },
  created() {
    var orderdata = JSON.parse(
      Decode.decode(localStorage.getItem("orderdata"))
    );
    this.fullname = orderdata.user.firstname + " " + orderdata.user.lastname;
    this.tel = orderdata.tel;
    this.address = orderdata.address + " " + orderdata.postalCode;
    // console.log("orderdata", orderdata);
    this.id = orderdata.id;

    this.getOrderById();
  },
  methods: {
    async getOrderById() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/orders/` + this.id
      );
      console.log("responseById", response.data.data);
      const data = response.data.data;
      this.orderNo = data.orderNo;
      this.orderType = data.orderType;
      this.orderdate = new Date(data.orderdate).toISOString().slice(0, 10);
      this.totalPrice = data.totalPrice;
      this.paymentType = data.paymentType;
      this.status = data.status;
      this.slipImg = data.slipImg;
      this.orderProducts = data.orderProducts;
      for (let i in this.orderProducts) {
        this.orderProducts[i].count =
          this.orderProducts[i].amount + " " + this.orderProducts[i].amountType;
      }
    },
    cancel() {
      this.$router.push("ManageOrder");
    },
    // ลูกค้าสั่ง >  รอการยืนยัน
    // พนง กดคอนเฟิม >  ยืนยันแล้วรอชำระเงิน
    // ลูกค้าจ่ายเงิน (แนบสลิป) > ชำระเงินแล้วรอจัดเตรียมสินค้า
    // พนง กดส่งของแล้ว > ถูกจัดส่งแล้ว
    step1() {
      this.status = "ยืนยันแล้วรอชำระเงิน";
      console.log(this.status);
      this.submit();
    },
    step2() {
      this.status = "ถูกจัดส่งแล้ว";
      console.log(this.status);
      this.submit();
    },
    step3() {
      this.status = "ยืนยันสินค้าให้ลูกค้ามารับของ";
      console.log(this.status);
      this.submit();
    },
    stepback() {
      this.status = "ยืนยันแล้วรอชำระเงิน";
      this.submit();
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          id: this.id,
          status: this.status,
          slipImg: "",
        };
        var user = JSON.parse(Decode.decode(localStorage.getItem("userForrent")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/orders/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `อัพเดตข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageOrder");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `อัพเดตข้อมูลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>